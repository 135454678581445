import React from 'react';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  return (
    <div className='logo-container' onClick={() => navigate('/')}>
      <div className="logo-bg"></div>
    </div>
  );
}

export default Header;
