import React from 'react';
import './Website.css'

const ContactForm = () => {
  return (
    <form action="https://formspree.io/f/mbjvoapj" method="POST">
      <div className="form-group">
        <label htmlFor="email">Your Email:</label>
        <input type="email" name="_replyto" id="email" required />
      </div>
      <div className="form-group">
        <label htmlFor="message">Message:</label>
        <textarea name="message" id="message" rows="4" required></textarea>
      </div>
      <button type="submit">Send</button>
    </form>
  );
};

export default ContactForm;
