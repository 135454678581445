import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import LandingPage from './components/Website/LandingPage';
import Frackagedon from './components/Website/Frackagedon';
import ScrollToTop from './ScrollToTop'; 
import ReactGA from "react-ga4";

ReactGA.initialize("G-GJNHELVXS3");


function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page:  window.location.pathname + window.location.search, title: "Page Hit" });

    // // Send a custom event
    // ReactGA.event({
    //   category: "your category",
    //   action: "your action",
    //   label: "your label", // optional
    //   value: 99, // optional, must be a number
    //   nonInteraction: true, // optional, true/false
    //   transport: "xhr", // optional, beacon/xhr/image
    // });
  }, [])



  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    const rootElement = document.documentElement;
    if (isDarkMode) {
      console.log("Switching to dark mode");
      rootElement.classList.add("dark-mode");
    } else {
      console.log("Switching to light mode");
      rootElement.classList.remove("dark-mode");
    }
  };
  
  
  
  return (
    <Router>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={< LandingPage />} />
          <Route path="/frackagedon" element={< Frackagedon />} />
        </Routes>
    </Router>
  );
}

export default App;
