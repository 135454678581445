import React from "react";
import bonfire from "./assetts/bonfire.png"; // Ensure correct path
import { Helmet } from 'react-helmet';
import bookPdf from './assetts/Histories Greatest Pusshounds.pdf';
import Header from "./Header";
import bookCover from './assetts/Cover.jpg';
import './Website.css';

const Frackagedon = () => {
  return (
    <div className="frackegedon-background">
      <div className="frackagedon-container">
        <Helmet>
          <title>DJFracking</title>
          <meta name="description" content="The Frackagedon" />
          <meta name="keywords" content="" />

          {/* Open Graph / WhatsApp / iMessage meta tags */}
          <meta property="og:title" content="Frackagedon" />
          <meta property="og:description" content="Frackagedon May 2, 2025." />
          <meta property="og:image" content={bonfire} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://djfracking.org/frackagedon" />

          {/* Twitter Card meta tags (optional for better previews) */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Frackagedon" />
          <meta name="twitter:description" content="Frackagedon May 2, 2025." />
          <meta name="twitter:image" content={bonfire} />
        </Helmet>

        <Header />

        <div className="bonfire-container">
          <img src={bonfire} alt="Bonfire" className="bonfire-image" />
        </div>
        
        <h1>Welcome to the Frackagedon</h1>
        <p>
          Friends, Americans, and Countrymen, you have been invited to the Frackagedon. 
          Please see below for more information. Due to the incredible amount of booze 
          and wine that we are planning to have, we are asking people to leave their firearms this year.
          I am looking forward to seeing you on the mountain.
      </p>
        
        <h4>Date: May 2th-4th</h4>
        
        <section className="mandatory-items">
          <h2>Mandatory Items</h2>
          <ul>
            <li>Sleeping bag, tent, hammock, or air mattress in the car.</li>
            <li>Water</li>
            <li>Rain gear</li>
            <li>Sacrifice - Bring an item to burn.</li>
          </ul>
        </section>
        
        <section className="recommended-items">
          <h2>Recommended Items</h2>
          <ul>
            <li>Food</li>
            <li>Fireworks</li>
            <li>Booze - Wine or Spirits preferred due to cooler space being limited</li>
            <li>Coolers and Ice</li>
            <li>Camping Chair</li>
          </ul>
        </section>

        <section className="book-section">
          <h2>My First Book - TOP SECRET</h2>
          <div className="underline"></div>
          <a href={bookPdf} download>
            <img className="bookImage" src={bookCover} alt="DJFracking's Book Cover" />
          </a>
          <p>Click on the book cover to download the PDF.</p>
        </section>

      </div>
    </div>
  );
};

export default Frackagedon;
