import React from 'react';
import { Helmet } from 'react-helmet';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/fonts.css';
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
const app = (
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <title>djfracking</title>
      <link rel="icon" href="./djfracking_logo_single_png.png" />
      <link rel="apple-touch-icon" href="./djfracking_logo_single_png.png" />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content="Software tools for the modern healthcare professional. Eligibility, Claims, Patients, and AI. Sign up today to streamline your healthcare practice." />
      <meta name="keywords" content="Healthcare Billing, Clinic Management, Telehealth, Insurance Eligibility, medical AI, Patient managment, patient electronic intake, Medical Software, HIPAA Compliant, Patient Eligibility, Batch Claims, AI in Healthcare" />
      <meta property="og:title" content="djfracking - Healthcare Billing & Clinic Management" />
      <meta property="og:description" content="Software tools for the modern healthcare professional. Eligibility, Claims, Patients, and AI. Sign up today to streamline your healthcare practice." />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/djfracking.appspot.com/o/djfracking_logo_wide.png?alt=media&token=72c8f353-f0f9-463c-966e-4f7b644bea5d" />  
  
    </Helmet>
    <App />
  </React.StrictMode>
);

if (typeof globalThis === 'undefined') {
  globalThis = window;
}

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

reportWebVitals();
