import React, { useState } from 'react';
import styles from './Website.css';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import DjPanel from './Djpanel';
import ContactForm from './ContactForm';
import djfracking from './assetts/DJ4.png';
import Header from './Header';
import { db } from '../../firebase'; // Import Firestore db instance
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const LandingPage = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleMailingListSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setMessage('Please enter a valid email address.');
            return;
        }

        setLoading(true);
        try {
            // Adding email to the 'mailingList' collection in Firestore
            await addDoc(collection(db, 'mailingList'), {
                email: email,
                timestamp: serverTimestamp(),
            });
            setMessage('Thanks for signing up!');
            setEmail('');
        } catch (error) {
            setMessage('There was an error signing you up. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="landing-page">
            <Helmet>
                <title>DJFracking</title>
                <meta name="description" content="DJ Fracking's Official Website" />
                <meta name="keywords" content="DJFracking, DJ" />
                <meta property="og:title" content="DJFracking" />
                <meta property="og:description" content="DJ Fracking's Official Website" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <Header />
            <div className="main-content">
                <main>
                    <div className="block">
                        <DjPanel />
                    </div>

                    <section>
                        <h2>DJFracking's Favorite Songs</h2>
                        <div className="underline"></div>
                        <div className="spotify-playlist">
                            <iframe
                                style={{ borderRadius: '12px' }}
                                src="https://open.spotify.com/embed/playlist/0ioBuRyZIsCAXCR2jmOuDo?utm_source=generator&theme=0"
                                width="100%"
                                height="452"
                                frameBorder="0"
                                allowFullScreen=""
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"
                                title="Spotify Playlist"
                            ></iframe>
                        </div>
                    </section>

                    <section className="services-section">
                        <h2>Services</h2>
                        <div className="underline"></div>
                        <ul className="services-list">
                            <li className="service-item"><span>DJ</span></li>
                            <li className="service-item"><span>Public Speaking</span></li>
                            <li className="service-item"><span>Verizon Certified Internet Installation</span></li>
                            <li className="service-item"><span>Hot-tub Mechanic</span></li>
                            <li className="service-item"><span>Event Management</span></li>
                            <li className="service-item"><span>Nuclear Disaster Relief</span></li>
                        </ul>
                    </section>

                    <section className="about-section">
                        <div className="left-div">
                            <div className="about-image">
                                <img src={djfracking} alt="DJFracking" />
                            </div>
                        </div>
                        <div className="right-div">
                            <div className="about-content">
                                <h3>About DJ Fracking</h3>
                                <p>
                                    DJ Fracking is an enigmatic figure, whose true identity remains hidden behind the sonic landscapes he creates and the vibrant atmospheres he conjures on stage. Unseen yet profoundly felt, he is a spirit that speaks to the soul through music, an elusive artist who defies the conventional confines of ordinary life.
                                </p>
                                <p>
                                    Beyond his musical persona, DJ Fracking is a true renaissance man. His polymathic prowess spans an astonishing array of fields: he is an engineer, a scientist, a mathematician, a philanthropist, a motorcycle enthusiast, a chef, a romantic, a painter, a nuclear disaster recovery specialist, a writer, a photographer, a businessman, a pilot, a hot tub mechanic, a hunter and fisherman, a chainsaw expert, and, of course, a DJ. He embodies the ideal of a universal person, embracing a multitude of passions with equal fervor.
                                </p>
                                <p>
                                    When night descends, DJ Fracking steps into his realm, crafting sonic symphonies that resonate with the heartbeat of the crowd. His performances are legendary, known far and wide for their grandeur. His entrances, as dramatic and mesmerizing as the music he delivers, never fail to captivate audiences.
                                </p>
                                <p>
                                    A scholar by passion, Fracking imbues each of his ventures with his multifaceted personality. The breadth and depth of his knowledge echo in his music, bringing a unique touch to every performance.
                                </p>
                                <p>
                                    Yet, for all his accolades, DJ Fracking remains shrouded in mystery. It is this combination of enigma and talent, secrecy and spectacle, that makes him not just a DJ, but a phenomenon—a spirit that flows through the crowds, bringing them what they crave and more.
                                </p>
                                <p>
                                    His journey is a testament to the power of music and the pursuit of diverse passions. It is a celebration of the unseen and the unexplored—a tribute to DJ Fracking, the legend.
                                </p>
                            </div>
                        </div>
                    </section>

                    {/* New Section: Mailing List Signup */}
                    <section className="mailing-list-section">
                        <h2>Join My Mailing List</h2>
                        <div className="underline"></div>
                        <p>Stay updated on new books, DJ sets, and the yearly Frackagedon.</p>
                        <form onSubmit={handleMailingListSubmit}>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button type="submit" disabled={loading}>
                                {loading ? 'Signing Up...' : 'Sign Up'}
                            </button>
                        </form>
                        {message && <p>{message}</p>}
                    </section>

                    <section>
                        <h2>Contact Me</h2>
                        <div className="underline"></div>
                        <ContactForm />
                    </section>

                </main>
            </div>

            <Footer />
        </div>
    );
};

export default LandingPage;
