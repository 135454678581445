import React from 'react';


function Footer() {
  return (
    <footer className="footer">
      
      <div className="logo-container">
      </div>
      
      <p className="all-rights-reserved">&copy; 2025 DJFracking. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
